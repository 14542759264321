import { Button, DatePicker, Form, Space } from 'antd';
import dayjs from 'dayjs';
import { useOverviewState } from './useOverviewState';

export function MonthTimePicker() {
	const form = Form.useFormInstance();
	const [stateParams, setStateParams] = useOverviewState();
	const now = dayjs();
	const next = dayjs().add(1, 'month');
	const previous = dayjs().subtract(1, 'month');

	return (
		<Form.Item label="时间">
			<div className="flex flex-row flex-wrap gap-2">
				<Form.Item name="time" noStyle>
					<DatePicker picker="month" changeOnBlur={true} />
				</Form.Item>

				<Button
					onClick={() => {
						setStateParams((v) => ({ ...v, time: now.valueOf() }));
						form.setFieldsValue({ time: now });
					}}
				>
					本月:{now.format('YYYY年MM月')}
				</Button>
				<Button
					onClick={() => {
						setStateParams((v) => ({ ...v, time: next.valueOf() }));
						form.setFieldsValue({ time: next });
					}}
				>
					下月:{next.format('YYYY年MM月')}
				</Button>
				<Button
					onClick={() => {
						setStateParams((v) => ({ ...v, time: previous.valueOf() }));
						form.setFieldsValue({ time: previous });
					}}
				>
					上月:{previous.format('YYYY年MM月')}
				</Button>
			</div>
		</Form.Item>
	);
}
